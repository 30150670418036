<template>
  <div class="dropdown-main" @focus="showListing = true" @blur="showListing = false" tabindex="1" ref="dropdownMain">
    <div :style="{ fontFamily: currentObject[mykey] }">{{ currentObject[mykey] }}</div>
    <div v-if="showListing" class="dropdown-listing">
      <div v-for="(it,ind) in items" :key="ind" :style="{ fontFamily: it[value] }" @click="selectFont(it)">{{ it[mykey] }}</div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { defineProps, onMounted, ref } from 'vue';

const props = defineProps({
  items: Array,
  mykey: String,
  value: String,
  selected: String
});
const emit = defineEmits([
  'update:selected'
])

const showListing = ref(false);
const currentObject = ref('');
const dropdownMain = ref(null);

const selectFont = (font) => {
  currentObject.value = font;
  dropdownMain.value.blur();
  emit('update:selected', currentObject.value[props.value]);
}

onMounted(() => {
  if (Array.isArray(props.items)) {
    if (props.items.length > 0) {
      currentObject.value = props.items[0];
      emit('update:selected', currentObject.value[props.mykey]);
    }
  }
})
</script>


<style scoped>
.dropdown-main {
  position: relative;
  z-index: 100;
  background-color: white;
}
.dropdown-listing {
  position: absolute;
  top: 100%;
  background-color: white;
  max-height: 200px;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  overflow: auto;
  border: 1px solid gray;
  border-top: none;
}
.dropdown-listing > div {
  border-bottom: 1px solid gray;
  padding: 5px;
  cursor: pointer;
  user-select: none;
}
.dropdown-listing > div:hover {
  background-color: rgb(200, 200, 200);
}
</style>